import "./styles/style.scss";
import { useContext, useEffect, useRef, useState } from "react";
import {
  UserInterface,
} from "types/ApiInterface";
import LoadingSuspense from "components/Suspense";
import "aos/dist/aos.css";
import PagesService from "api/services/PagesService";
import { Theme } from "pages/Home";
import { StateContext } from "store/Provider";
import { Helmet } from "react-helmet";
import { ActionKind } from "store/Reducer";
import UtilityHelper from "helpers/UtilityHelper";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import DateTimeHelper from "helpers/DateTimeHelper";
import EmptyFeed from "assets/PublicProfile/empty/empty_feed.png";

<Helmet>
  <script src="../../js/jquery-3.1.1.min.js" type="text/javascript" />
  <script src="../../js/flickity.min.js" type="text/javascript" />
  <script src="../../js/easypiechart.min.js" type="text/javascript" />
  <script src="../../js/parallax.js" type="text/javascript" />
  <script src="../../js/typed.min.js" type="text/javascript" />
  <script src="../../js/datepicker.js" type="text/javascript" />
  <script src="../../js/isotope.min.js" type="text/javascript" />
  <script src="../../js/ytplayer.min.js" type="text/javascript" />
  <script src="../../js/lightbox.min.js" type="text/javascript" />
  <script src="../../js/granim.min.js" type="text/javascript" />
  <script src="../../js/jquery.steps.min.js" type="text/javascript" />
  <script src="../../js/countdown.min.js" type="text/javascript" />
  <script src="../../js/twitterfetcher.min.js" type="text/javascript" />
  <script src="../../js/spectragram.min.js" type="text/javascript" />
  <script src="../../js/smooth-scroll.min.js" type="text/javascript" />
  <script src="../../js/scripts.js" type="text/javascript" />
</Helmet>

function Blog() {
  let params = useParams();
  const navigate = useNavigate();
  const [isHome, setIsHome] = useState(true);
  const [user, setUser] = useState<UserInterface>();
  const [site, setSite] = useState();
  const [loading, setLoading] = useState(true);
  const formRef = useRef<HTMLInputElement>(null);
  const { state, dispatch } = useContext(StateContext);
  const [pages, setPages] = useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = useState();
  const footerRef = useRef<HTMLDivElement>(null);
  const publishedBlogs = useState();

  useEffect(() => {
    setLoading(true);
    UtilityHelper.initApp(state).then((val) => {
      setSite(val.website);
      setUser(val.user);
      dispatch({ type: ActionKind.SET_CURRENT_WEBSITE, payload: val.website });
      dispatch({ type: ActionKind.SET_CURRENT_USER, payload: val.user });
      if (params.slug && params.slug != "null") {
        PagesService.GetPages({ user_id: val.user.id, permaid: params.slug }).then(
          (val) => {
            if (!val.length) {
              navigate("/news");
            }
            setCurrentPage(val[0]);
            setLoading(false);
          }
        );
      }
      else {
        PagesService.GetPages({
          user_id: val.user.id, page_type: "blog_post",
        }).then(
          (val) => {
            const publishedPages = val.filter((blog: { snap_blog_status: string; snap_blog: any }) => blog.snap_blog_status === 'published' && UtilityHelper.isPresent(blog.snap_blog));
            //Neu khong co publish blog thi back ve home
            if (!UtilityHelper.isPresent(publishedPages)) {
              navigate("/home");
            };
            //aaaaaaaaaaa
            // Đặt giá trị của publishedPages vào state
            setPages(publishedPages);
            setLoading(false);
            console.log(pages[0])
          }
        );
      }
    });
  }, []);


  const setHome: () => JSX.Element = () => {
    return (
      <div className="sections-container flex-vertical">
        {pages.length ? <section className="cover height-70 imagebg" data-overlay="3">
          <div className="background-image-holder" style={{ backgroundImage: "url(" + pages[0].snap_blog?.feature_image + ")", opacity: '1', backgroundSize: "auto", }}>
            <img alt="background" src={pages[0].snap_blog.feature_image ? pages[0].snap_blog?.feature_image : EmptyFeed} style={{ width: '100%', height: '290px', borderRadius: ' 6px 6px 0px 0px ' }} />
          </div>
          <div className="container pos-vertical-center">
            <div className="col-md-12">
              <h1 className="h1--large">
                {pages[0].name}
              </h1>
              <h5 className="">{DateTimeHelper.formatDateString(pages[0].updated_at)}</h5>
              <a className="btn btn--primary type--uppercase" onClick={() => {
                setCurrentPage(pages[0]);
                navigate(`/news/${pages[0].permaid}`);
              }} style={{ borderRadius: '0px' }}>
                <span className="btn__text">
                  Read more
                </span>
              </a>
            </div>
          </div>
        </section> : null}
        {pages.length ? <section className="switchable feature-large">
          <div className="container_Tab">
            <div className="row justify-between">
              <div className=" col-lg-2 ml-9 mr-9">
                <div className="text-block">
                  <h2>Top Blog</h2>
                  {pages.map((page, index) => (
                    <a onClick={() => {
                      setCurrentPage(page);
                      navigate(`/news/${page.permaid}`);
                    }}
                      style={{ cursor: 'pointer', }} key={page.id} className="block">
                      {index === 0 ? (
                        <h5>{page.name}</h5>
                      ) : <p>{page.name}</p>}

                    </a >
                  ))
                  }
                </div>
                <div className="text-block">
                  <h2>Lastest Blog</h2>
                  {pages.slice().sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()).map((page, index) => (
                    <a onClick={() => {
                      setCurrentPage(page);
                      navigate(`/news/${page.permaid}`);
                    }}
                      style={{ cursor: 'pointer', }} key={page.id} className="block">
                      {index === 0 ? (
                        <h5>{page.name}</h5>
                      ) : <p>{page.name}</p>}

                    </a >
                  ))
                  }
                </div>
              </div>
              <div className=" col-lg-8 ml-9 mr-9" >
                <div className="grid-container">
                  {pages.map((page, index) => (
                    <a onClick={() => {
                      setCurrentPage(page);
                      navigate(`/news/${page.permaid}`);
                    }}
                      style={{ cursor: 'pointer' }} key={page.id} className="block">
                      <div className="grid-item">
                        <article className="feature-1">
                          <img alt="Feature Image" style={{ width: '100%', height: '290px', borderRadius: ' 6px 6px 0px 0px ' }} src={page.snap_blog.feature_image ? page.snap_blog?.feature_image : EmptyFeed} />
                          <div className="feature__body boxed boxed--border" style={{ borderRadius: ' 0px 0px 6px 6px ' }}>
                            <span>{DateTimeHelper.formatDateString(page.updated_at)}</span>
                            <h5>{page.name}</h5>
                            <a className="readmore" onMouseEnter={(e) => e.currentTarget.style.textDecoration = 'underline'}
                              onMouseLeave={(e) => e.currentTarget.style.textDecoration = 'none'} style={{ cursor: 'pointer', textDecoration: 'none', color: '#4a90e2' }}>
                              Read more
                            </a>
                          </div>
                        </article >
                      </div >
                    </a >
                  ))
                  }
                </div >
              </div>
            </div>
          </div >
        </section > : null}
      </div >
    );
  };

  return <>{loading ? <LoadingSuspense /> : setHome()}</>;
}

export default Blog;
