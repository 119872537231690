import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";

class WebsiteService {
  static type = "website";

  static GetWebsite = async (where: any) => {
    const url = ApiConfig.API_URL + ApiConfig.WEBSITES_INDEX;

    const response = await ApiClient.sendGet(url, where, false);
    return response;
  };

  static Update = async (id: any, attributes: any) => {
    const url = ApiConfig.API_URL + ApiConfig.WEBSITES_UPDATE;

    const response = await ApiClient.sendPatch(this.type, url, id, attributes);
    return response;
  };

  static getAiContent = async (where: any = {}) => {
    const url = ApiConfig.API_URL + ApiConfig.WEBSITES_AI;

    const response = await ApiClient.sendPost(null, url, where, true);
    return response;
  } 
}

export default WebsiteService;
