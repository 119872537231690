import { useSearchParams } from "react-router-dom";
import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";
import UserService from "api/services/UserService";
import ChatRoomService from "./ChatRoomService";
import PushNotificationService from "./PushNotiService";
import AccountService from "./AccountService";

class AuthService {
  static createPreviewUser = async () => {
    const params = {
    };
    const response = await ApiClient.sendPost(
      null,
      ApiConfig.API_URL + ApiConfig.USER_PREVIEW_SIGNUP,
      params,
      false
    );
    await this.SaveAuth(response);
    return response;
  };

  static loginGoogle = async (user: any, domain: any = "") => {
    const params = {
      action_task: "social_auth",
      provider: "google_mobile",
      preview_domain: domain,
      token: user.access_token,
      ip: "",
    };
    const response = await ApiClient.sendPost(
      null,
      ApiConfig.API_URL + ApiConfig.USER_OAUTH,
      params,
      false
    );
    this.SaveAuth(response);
    return response;
  };

  static SaveAuth = async (response: any) => {
    const refreshToken = response.refresh_token;
    const expiry = response.access_token_expiry;
    const accessToken = response.access_token;

    if (refreshToken && expiry && accessToken) {
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("expiry", expiry);

      const userId = response.id;
      if (userId) {
        let users = await UserService.GetUsers({
          id: userId,
        });

        
        if (users.length) {
          this.SaveCurrentUser(users[0]);

          let accounts = await AccountService.GetAccounts({
            id: users[0]["account_id"],
          });
          this.SaveCurrentAccount(accounts[0]);
          console.log("current account 2");
          console.log(accounts);
        }
      }
    }
  };

  static SaveCurrentUser = (user: any) => {
    localStorage.setItem("current_user", user ? JSON.stringify(user) : "");
  };

  static SaveCurrentAccount = (account: any) => {
    localStorage.setItem("current_account", account ? JSON.stringify(account) : "");
  };

  static GetCurrentUser = () => {
    let currentUserJson = localStorage.getItem("current_user");
    if (currentUserJson) {
      return JSON.parse(currentUserJson);
    }
  };

  static GetCurrentAccount = () => {
    let currentAccountJson = localStorage.getItem("current_account");
    if (currentAccountJson) {
      console.log("current account");
      console.log(JSON.parse(currentAccountJson));
      return JSON.parse(currentAccountJson);
    }
  };

  static RefreshAuth = async () => {
    const currentTime = Date.now().toString().slice(0, 10);
    const expiryTime = localStorage.getItem("expiry")!.toString();
    const refreshToken = localStorage.getItem("refreshToken")!.toString();
    const apiUrl = ApiConfig.API_URL + ApiConfig.USER_OAUTH;

    if (!expiryTime) {
      return false;
    }

    if (expiryTime > currentTime) {
      return false;
    }

    try {
      const response = await ApiClient.sendPost(
        null,
        apiUrl,
        {
          action_task: "social_auth",
          refresh_token: refreshToken,
        },
        false
      );

      this.SaveAuth(response);
    } catch (error) {
      console.error(error);
    }
  };

  static Logout = () => {
    localStorage.setItem("refreshToken", "");
    localStorage.setItem("accessToken", "");
    localStorage.setItem("expiry", "");
    localStorage.setItem("current_user", "");
    const unregisterTopics = async () => {
      try {
        const data = await ChatRoomService.GetChatRoomList({
          user_id: AuthService.GetCurrentUser().id,
        });
      } catch (err) {
        console.log(err);
      }
    };
    unregisterTopics();
  };

  // TODO: need works
  static IsLoggedIn = async () => {
    const accessToken = localStorage.getItem("accessToken")
      ? localStorage.getItem("accessToken")!.toString()
      : null;
    const refreshToken = localStorage.getItem("refreshToken")
      ? localStorage.getItem("refreshToken")!.toString()
      : null;
    const currentTime = Date.now().toString().slice(0, 10);
    const expiryTime = localStorage.getItem("expiry")
      ? localStorage.getItem("expiry")!.toString()
      : null;

    if (!accessToken || !refreshToken) {
      return false;
    }

    if (!expiryTime) {
      return false;
    }

    if (expiryTime > currentTime) {
      // TODO: need to complete this one
      let refreshAuthResult = await this.RefreshAuth();
    }

    return true;
  };
}

export default AuthService;
