import AccountService from "api/services/AccountService";
import WebsiteService from "api/services/SiteService";

class TrackerHelper {
  static matomoVirtualPage(url: any, name: any) {
    if (window._paq) {
      window._paq.push(['setCustomUrl', url]);
      window._paq.push(['setDocumentTitle', name]);
      window._paq.push(['trackPageView']);
    }
  };
}

export default TrackerHelper;
