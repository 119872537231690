import "./App.scss";
import {
  BrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { StateContext, StateProvider } from "store/Provider";
import reducer, { initialState } from "store/Reducer";
import MPService from "api/services/MixpanelService";
import "broadcastchannel-polyfill";
import PolicyPage from "pages/Policy";
import TermsPage from "pages/Terms";
import Home from "pages/Home";
import Page404 from "pages/404";
import { ParallaxProvider } from 'react-scroll-parallax';
import Blog from "pages/Blog";
import Unsubscribe from "pages/unsubscribe/Unsubscribe";
import Successful from "pages/unsubscribe/Successful"
import PublicProfile from "pages/PublicProfile";
import BlogLayout from "pages/Blog/layout";
import BlogPost from "pages/Blog/blog_post";
import Onboarding from "pages/Onboarding";
import SignIn from "pages/Auth/signin";
import Download from "pages/Auth/download";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Restricted from "routes/restricted";

function App() {
  // //Mixpanel initialize
  // MPService.Init(`${process.env.REACT_APP_MIXPANEL_API_KEY}`, {
  //   debug: true,
  //   ignore_dnt: true,
  //   loaded: () => {
  //     MPService.Register({
  //       name: "visitor",
  //     });
  //   },
  // });

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <BrowserRouter>
        <ParallaxProvider>
          <Routes>
            {/* Home Route */}
            <Route path="/" element={<Home />} />
            <Route path="/onboarding" element={<Restricted><Onboarding /></Restricted>} />
            <Route path="/signin" element={<Restricted><SignIn /></Restricted>} />
            <Route path="/download" element={<Restricted><Download /></Restricted>} />

            {/* Unsubscribe Route */}
            {/* <Route path="/unsubscribe" element={<Unsubscribe />} /> */}
            <Route path="/unsubscribe/:user_key" element={<Unsubscribe/>} />
            <Route path="/unsubscribe-successful" element={<Successful />} />

            {/* Blog Routes */}
            <Route path="/news" element={<BlogLayout />}>
              <Route index element={<Blog />} />
              <Route path=":slug" element={<BlogPost />} />
            </Route>

            {/* Public Profile (Wildcard Route) */}
            <Route path="/:pagePermaid" element={<PublicProfile />} />

            {/* Other Static Routes */}
            <Route path="/privacy-policy" element={<Restricted><PolicyPage /></Restricted>} />
            <Route path="/terms-of-service" element={<Restricted><TermsPage /></Restricted>} />

            {/* Catch-All 404 Route */}
            <Route path="*" element={<Page404 />} />
          </Routes>
          <ToastContainer position="top-right" />
        </ParallaxProvider>
      </BrowserRouter>
    </StateProvider>
  );
}

export default App;
