import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";

class PagesService {
  static type = "pages";

  static GetPages = async (where: any) => {
    const url = ApiConfig.API_URL + ApiConfig.PAGES_INDEX;

    try {
      const response = await ApiClient.sendGet(url, where, false);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
}

export default PagesService;
