import React, { useEffect } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import AuthService from 'api/services/authService';
import TrackerHelper from 'helpers/TrackerHelper';

interface Step1Props {
  onComplete: () => void;
}

const Step1: React.FC<Step1Props> = ({ onComplete }) => {
  useEffect(() => {
    const initialize = async () => {
      await AuthService.createPreviewUser();
      onComplete();
    };
    TrackerHelper.matomoVirtualPage("/onboarding/step_loading", "Onboarding - Loading");

    initialize();
  }, [onComplete]);

  return (
    <div className="loading-container text-center">
      <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
    </div>
  );
};

export default Step1;
